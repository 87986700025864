import { type AchillesUser } from '@/schemas/users';
import { podiAxios } from '@/utils/api';
import { orderBy } from 'lodash';

export const getUsers = () => {
  return podiAxios<AchillesUser[]>('/api/User').then((users) => {
    const cleanUsers = users.map((user) => {
      return {
        ...user,
        firstName: user.firstName.trim(),
        lastName: user.lastName.trim(),
        email: user.email.toLowerCase()
      };
    });

    return orderBy(cleanUsers, 'email', 'asc');
  });
};
